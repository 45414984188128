import React from 'react'
import { Link } from 'react-router-dom';
import '../css/footer.css';
import lcm2023_logo from '../images/lcm2023_logo.png';

function Footer(): React.ReactElement {


  return (
    <section id='product-section-footer'>
        <picture>
        <img src={lcm2023_logo} alt="lCM 2023 logo" />
        </picture>
        <div className='product-section-footer-slogan-container'>
          <p>Come and discover how these impacts were measured at the Karbon booth.</p>
        </div>
        <br />
        {/* <div className='cookies-link-container'>
          <Link to={'javascript:openAxeptioCookies()'}>
            Click here to update your cookies settings
          </Link>
        </div> */}

    </section>
  )
}

export default Footer