import React from 'react';
import { Navigate } from 'react-router-dom';
import Home from './screens/Home';
import { ServiceType } from './types/types';
import data from './data/data.json';

function Root(): React.ReactElement {

  const servicesByDay: ServiceType[] = data.services || [];

  // UTC Date
  const currentDateUTC: Date = new Date(); 
  currentDateUTC.setHours(currentDateUTC.getHours() + 2); 
  const currentDay: number = parseFloat(currentDateUTC.toISOString().slice(8, 10));

  // Redirection Route
  const getRedirectionRoute = (servicesByDay: ServiceType[], currentDay: number) => {
    const currentService: ServiceType[] | [] = servicesByDay.filter(service => service.currentDay === currentDay);

    if(currentService.length > 0 && currentService[0].currentDay) {
      return `/service/${currentService[0].day}`;
    }
    return '';
  }
  const redirectionRoute = getRedirectionRoute(servicesByDay, currentDay);

  // Redirection
  const isUserRedirected = () => {
    const hasBeenRedirected = sessionStorage.getItem('hasBeenRedirected');

    if (!hasBeenRedirected) {
      sessionStorage.setItem('hasBeenRedirected', 'true');
      return true;

    } else {
      return false;
    }
  };
  const redirection = isUserRedirected();

  const displayHome = redirection && redirectionRoute
    ? <Navigate to={redirectionRoute} replace={true} />
    : <Home />
  ;

  return displayHome;
}

export default Root;
