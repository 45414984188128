import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Title from '../components/Title';
import Footer from '../components/Footer';

import data from '../data/data.json';
import { ServiceType } from '../types/types';
import '../css/home.css';

function Home(): React.ReactElement {
  const servicesByDay: ServiceType[] | null = data.services ? data.services : null;

  const displayServicesbyDay = servicesByDay
    ? (
      <section id="home-service-days-section">
        <p className="section-hint">Pick the service day :</p>
        {servicesByDay.map((serviceOfTheDay: ServiceType, i: number) => (
          <div className="home-service-days-link-container" key={'day-' + i}>
            <Link to={`/service/${serviceOfTheDay.day}`}>{serviceOfTheDay.long_date}</Link>
          </div>
          ))}
      </section>
    )
    : null;

  return (
    <>
      <Header />
      <Title/>
      {displayServicesbyDay ? displayServicesbyDay : <></>}
      <Footer />
    </>
  );
};

export default Home;
