import React from 'react'
import '../css/title.css';

function Title(): React.ReactElement {

  return (
    <section id='title-section'>
      <div className='title-container'>

        <h1>
          <span className='underlined'>Food impact</span>
          <br />
          <span className='underlined'>calculation</span>
        </h1>
        <p>LCM 2023 conference</p>
      </div>
    </section>
  )
}

export default Title