import React, { useState } from 'react';
import { ScrollRestoration, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ComparisonCarousel from '../components/ComparisonCarousel';
import { ProductType, CategoryType, ImpactType  } from '../types/types';
import { NavLinkProps } from '../types/interfaces';
import '../css/product.css';

function Product(): React.ReactElement {
  const location = useLocation();
  const state: {product: ProductType, category: CategoryType,serviceNavLink: NavLinkProps} | undefined = location.state;
  const [productDisplayed, setProductDisplayed] = useState(state?.product);
  const productNavLink = state?.serviceNavLink
    ? [state.serviceNavLink, {url: '/product/' + state?.product.slug, name: state?.product.slug, type: 'product'}] 
    : [{url: '/product/' + state?.product.slug, name: state?.product.slug, type: 'product'}];

    // Display all impacts
    const [displayAllImpacts, setDisplayAllImpacts] = useState(false);
    const numberOfImpacts = displayAllImpacts === true ? 16 : 2;
    const displayImpacts = () => {
      setDisplayAllImpacts(!displayAllImpacts);
    }
  // Display product info
  const displayProduct = !state || !state.product || !state.category ? 
    <>
      <h2>Product not found.</h2>
    </> :
    <>
      {/* Title */}
      <div className="product-card">

        <section id='product-section-title'>

          <picture>
            <img src={require(`../images/${state.product.img}.png`)} alt={state.product.img} />
          </picture>

          <div id='product-section-title-info-container'>
            <h1>{state.product.name}</h1>
            <p className='product-title-name-details'>{state.product.name_details}</p>
            <p>{state.category.name}</p>
          </div>

        </section>

        {/* Product Score */}
        <section id='product-section-environmental-score'>
          <div className="label-section">
            <h2>Environmental score</h2>
            <p>Single score PEF</p>
          </div>

          <div className='score-section'>
            <div className='product-section-environmental-score-pastil' style={{backgroundColor: state.product.bg_color }}></div>
            <div className="score-text">
              <p id='product-section-environmental-score-scoring'>{state.product.mPt_score + ' mPt'}</p>
              <p>per portion</p>
            </div>
          </div>

        
        </section>
      </div>

      {/* Impacts */}
      <section id='product-section-environmental-impacts'>
        <h2>Environmental impacts</h2>
        <div id="product-section-environmental-impacts-cards-container">

        {/* Impacts List */}
        {state.product.environmental_impacts?.slice(0, numberOfImpacts).map((impact: ImpactType, impactId: number) => (
          <div className='product-section-environmental-impacts-card' key={'card-' + impactId}>

              {impactId < 2 
                ?
                  <>
                    <div className='product-section-environmental-impacts-card-info'>
                      <p className='product-impacts-card-info-name'>{impact.name}</p>
                      <p className='product-impacts-card-info-scoring'>{impact.scoring}</p>
                      <p className='product-impacts-card-info-unit'>{impact.unit_name}</p>
                    </div>
                    <div className='product-section-environmental-impacts-card-comparison'>
                      <span role="img" aria-label={impact.label}>{impact.comparison_icon}</span>
                      <p className='product-impacts-card-comparison-scoring'>
                        {impact.comparison_score && impact.comparison_score + ' ' + impact.comparison_unity}
                        {/* impactId => 'klm' */}
                        {impact.comparison_score && impact.comparison_score >= 1 && impactId > 0 ? 's' : ''}
                      </p>
                    </div>
                  </>
                : 
                  <>
                    <div className='product-section-environmental-impacts-secondary-card-info'>
                      <p className='product-impacts-card-info-name'>{impact.name}</p>
                    </div>
                    <div className='product-impacts-secondary-card-info'>
                      <p className='product-impacts-card-info-scoring'>{impact.scoring}</p>
                      <p className='product-impacts-card-info-unit'>{impact.unit_name}</p>
                    </div>
                  </>
              }
          </div>
        ))}

        </div>

        {/* Show more impacts */}
        <div className='product-section-environmental-impacts-display-more'>
          <p onClick={displayImpacts}>{displayAllImpacts ? '- hide ' : '+ 13 '}others impacts</p>
        </div>
      </section>

      {/* Category Score */}
      <section id='product-section-category-score'>
        <h2>Other {state.category.name.toLowerCase()} served today.</h2>
        <div id='product-section-category-score-gauge-container'>
          <ComparisonCarousel
            products={state?.category.products}
            productDisplayed={productDisplayed!}
            category={state.category}
            onProductDisplayedChange={(product) => setProductDisplayed(product)}
          />

          {/* Category gauge */}
          <div id='category-score-gauge'>
            <div 
              className='category-product-score-indicator' 
              style={{
                left: `calc(${state.product.percent_jauge_position}% - 5% - ${
                  (state.product.name === productDisplayed?.name) ? '8px' : '5px'
                })`,
                backgroundColor: state.product.bg_color,
                ...(state.product.name === productDisplayed?.name ? {
                  width: 16,
                  height: 16,
                  top: -11,
                  zIndex: 1,
                } : {})
              }}
              onClick={() => setProductDisplayed(state.product)}
            >
            </div>

            {/* Indicator points */}
            {state?.category.products.map((comparisonProduct: ProductType) => (
              comparisonProduct.name !== state.product.name 
                ? 
                  <div 
                    className='category-product-score-indicator' 
                    key={'comparison_product_' + comparisonProduct.slug}
                    style={{
                      left: `calc(${comparisonProduct.percent_jauge_position}% - 5% - ${
                        (comparisonProduct.name === productDisplayed?.name) ? '8px' : '5px'
                      })`,
                      backgroundColor: comparisonProduct.bg_color,
                      ...(comparisonProduct.name === productDisplayed?.name ? {
                        width: 16,
                        height: 16,
                        top: -11,
                        zIndex: 1,
                      } : {})
                    }}
                    onClick={() => setProductDisplayed(comparisonProduct)}
                  >
                  </div>
                : ''
              ))
            }

          </div>
        </div>
      </section>
    </>
     ;

  return (
    <div>
      <Header navLinks={productNavLink} category={state?.category} product={state?.product} />
      {displayProduct}
      <Footer />
      <ScrollRestoration />
    </div>
  );
};

export default Product;
