import React, { useEffect, useState } from 'react';
import { CategoryType, ProductType } from '../types/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

type Props = {
  products: ProductType[],
  productDisplayed: ProductType,
  category: CategoryType,
  onProductDisplayedChange: (product: ProductType) => void,
}

export default function ComparisonCarousel({
  products,
  productDisplayed,
  category,
  onProductDisplayedChange
}: Props){
  const [visibleProduct, setVisibleProduct] = useState(productDisplayed);
  function onScroll(e: React.UIEvent<HTMLDivElement>){
    const carouselWidth = window.document.getElementById("carousel-container")?.offsetWidth;
    const position = Math.round((e.target as HTMLElement).scrollLeft / (carouselWidth || 1));
    if (products[position].name !== visibleProduct.name) {
      setVisibleProduct(products[position]);
      onProductDisplayedChange(products[position]);
    }
  }
  useEffect(() => {
    const currentIndex = products.findIndex((product) => product.name === productDisplayed.name);
    const carouselWidth = window.document.getElementById("carousel-container")?.offsetWidth;
    const carouselScrollLeft = window.document.getElementById("carousel-container")?.scrollLeft;
    if (currentIndex !== Math.round((carouselScrollLeft || 0) / (carouselWidth || 1))) {
      window.document.getElementById("carousel-container")?.scrollTo({
        left: currentIndex * (carouselWidth || 1),
        behavior: 'smooth',
      });
    }
  }, [productDisplayed])
  const productDisplayedIndex = products.findIndex((product) => product.name === productDisplayed.name);
  return (
    <>
      <div onClick={() => {
        productDisplayedIndex && onProductDisplayedChange(products[productDisplayedIndex - 1])
      }} className={`left-arrow-group ${!productDisplayedIndex ? 'disabled' : ''}`}>
        <FontAwesomeIcon icon={faArrowLeft} />
        <p>Better scores ({productDisplayedIndex})</p>
      </div>
      <div id="carousel-container" onScroll={onScroll}>
        {products.sort(
          (a: ProductType, b: ProductType) => a.mPt_score - b.mPt_score,
        ).map((product: ProductType) => (
          <div key={product.slug} id={product.slug} className="product-card-container">
            <div className='category-product-card'>
              <picture className='category-product-card-picture'>
              <img src={require(`../images/${product.img}.png`)} alt={product.img} />
              </picture>
              <div className='category-product-info'>
                <h3>{product.name}</h3>
                <p>{category.name}</p>
                <div className='score-section'>
                  <div className='product-section-environmental-score-pastil' style={{backgroundColor: product.bg_color }}></div>
                  <div className="score-text">
                    <p id='product-section-environmental-score-scoring'>{product.mPt_score + ' mPt'}</p>
                    <p>per portion</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div onClick={() => {
        (productDisplayedIndex < (products.length - 1)) && onProductDisplayedChange(products[productDisplayedIndex + 1])
      }} className={`right-arrow-group ${!(products.length - productDisplayedIndex - 1) ? 'disabled' : ''}`}>
        <FontAwesomeIcon icon={faArrowRight} />
        <p>Worse scores ({products.length - productDisplayedIndex - 1})</p>
      </div>
    </>
  );
};
